import { __decorate } from "tslib";
import AppAutocomplete from "@/components/shared/ui/AppAutocomplete.vue";
import AppBtn from "@/components/shared/ui/AppBtn.vue";
import AppCreateSidenav from "@/components/shared/ui/AppCreateSidenav.vue";
import AppSelect from "@/components/shared/ui/AppSelect.vue";
import AppTextField from "@/components/shared/ui/AppTextField.vue";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Component, Emit, Prop, ProvideReactive, VModel, Vue, } from "vue-property-decorator";
let UnitCreate = class UnitCreate extends Vue {
    constructor() {
        super(...arguments);
        this.isSubmitting = false;
        this.fields = null;
        this.showIdError = false;
    }
    get title() {
        return this.editedItem
            ? `Modifier ${this.editedItem.name}`
            : "Nouvelle unité";
    }
    get isFormValid() {
        return (!!this.fields?.name && !!this.fields?.company && !!this.fields?.group);
    }
    get showDelete() {
        return !!this.editedItem;
    }
    get companyItems() {
        return unitReferencesModule.unitReferences?.reduce((items, curr) => {
            if (!items.find((i) => i.value === curr.company))
                items.push({ value: curr.company, label: curr.company });
            return items;
        }, []);
    }
    get groupItems() {
        return unitReferencesModule.unitReferences?.reduce((items, curr) => {
            if (!items.find((i) => i.value === curr.company))
                items.push({ value: curr.company, label: curr.company });
            return items;
        }, []);
    }
    created() {
        this.prefillForm();
    }
    saveItem() {
        if (this.isSubmitting || !this.isFormValid)
            return;
        const data = {
            ...this.fields,
        };
        unitReferencesModule
            .upsertUnit(data)
            .then(() => {
            this.$emit("saved", true);
            this.showSidenav = false;
        })
            .finally(() => (this.isSubmitting = false));
    }
    prefillForm() {
        this.fields = {
            id: "",
            name: "",
            company: "",
            group: "",
            ...this.editedItem,
        };
    }
    deleteItem() {
        this.showSidenav = false;
        return this.editedItem;
    }
};
__decorate([
    VModel({ default: false })
], UnitCreate.prototype, "showSidenav", void 0);
__decorate([
    Prop()
], UnitCreate.prototype, "editedItem", void 0);
__decorate([
    ProvideReactive()
], UnitCreate.prototype, "title", null);
__decorate([
    ProvideReactive()
], UnitCreate.prototype, "isFormValid", null);
__decorate([
    ProvideReactive()
], UnitCreate.prototype, "showDelete", null);
__decorate([
    ProvideReactive()
], UnitCreate.prototype, "isSubmitting", void 0);
__decorate([
    Emit("deleteItem")
], UnitCreate.prototype, "deleteItem", null);
UnitCreate = __decorate([
    Component({
        components: {
            AppBtn,
            AppTextField,
            AppSelect,
            AppCreateSidenav,
            AppAutocomplete,
        },
    })
], UnitCreate);
export default UnitCreate;
