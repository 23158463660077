export class FormHelper {
    /**
     * Add invalid class to all input & select fields inside a form
     */
    static highlightInvalidControls(form) {
        if (!form)
            return;
        const inputs = form.querySelectorAll("input");
        inputs?.forEach((input) => {
            if (!input.validity.valid) {
                input.classList.add("invalid");
            }
        });
        const textareas = form.querySelectorAll("textarea");
        textareas?.forEach((textarea) => {
            if (!textarea.validity.valid) {
                textarea.classList.add("invalid");
            }
        });
        const selects = form.querySelectorAll("select");
        selects?.forEach((select) => {
            if (!select.validity.valid) {
                const materializeWrapper = select.parentElement;
                if (materializeWrapper.classList.contains("select-wrapper")) {
                    materializeWrapper.classList.add("invalid");
                }
            }
        });
    }
}
