import { __decorate } from "tslib";
import { NoCache } from "@/helpers/NoCache";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppModal from "../shared/ui/AppModal.vue";
import UnitCreate from "./units/UnitCreate.vue";
let UnitList = class UnitList extends Vue {
    constructor() {
        super(...arguments);
        this.showDeleteConfirmModal = false;
        this.showUnitCreate = false;
        this.unitToDelete = null;
        this.editedUnit = null;
        this.showIssues = true;
    }
    get units() {
        return unitReferencesModule.unitReferences
            ? [...unitReferencesModule.unitReferences].sort((a, b) => {
                if (a.group > b.group)
                    return 1;
                if (b.group > a.group)
                    return -1;
                if (a.company > b.company)
                    return 1;
                if (b.company > a.company)
                    return -1;
                if (a.name > b.name)
                    return 1;
                if (b.name > a.name)
                    return -1;
                return 0;
            })
            : null;
    }
    // get groups() {
    //   return this.units
    //     ?.reduce((groups, curr) => {
    //       if (!groups.includes(curr.group)) groups.push(curr.group);
    //       return groups;
    //     }, [] as string[])
    //     .sort();
    // }
    // getGroupCompanies(group: string) {
    //   return this.units
    //     ?.reduce((companies, curr) => {
    //       if (curr.group === group && !companies.includes(curr.company))
    //         companies.push(curr.company);
    //       return companies;
    //     }, [] as string[])
    //     .sort();
    // }
    mounted() {
        unitReferencesModule.loadUnits();
        this.$events.$emit("menu-change", {
            menu: "Paramètres",
            subMenu: "Indicateurs",
        });
    }
    editUnit(unit) {
        this.editedUnit = unit;
        this.showUnitCreate = true;
    }
    deleteUnit(unit) {
        this.unitToDelete = unit;
        this.showDeleteConfirmModal = true;
    }
    onDeleteConfirm(confirmed) {
        if (confirmed)
            unitReferencesModule.deleteUnit(this.unitToDelete.id);
        this.unitToDelete = null;
        this.showDeleteConfirmModal = false;
    }
    onShowUnitCreate(showUnitCreate) {
        if (!showUnitCreate)
            this.editedUnit = null;
    }
};
__decorate([
    NoCache()
], UnitList.prototype, "units", null);
__decorate([
    Watch("showUnitCreate")
], UnitList.prototype, "onShowUnitCreate", null);
UnitList = __decorate([
    Component({
        components: {
            UnitCreate,
            AppModal,
        },
    })
], UnitList);
export default UnitList;
