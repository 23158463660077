import { __decorate } from "tslib";
import { FormHelper } from "@/helpers/FormHelper";
import { Component, InjectReactive, Prop, VModel, Vue, Watch, } from "vue-property-decorator";
import AppContentSidenav from "./AppContentSidenav.vue";
let AppCreateSidenav = class AppCreateSidenav extends Vue {
    constructor() {
        super(...arguments);
        this.hasUserInput = false;
        this.overlayClickHandler = (e) => {
            e.stopPropagation();
        };
    }
    get isDisabled() {
        return this.isSubmitting || !this.hasUserInput;
    }
    get isEmptyItem() {
        return !this.editedItem || !Object.keys(this.editedItem).length;
    }
    beforeDestroy() {
        this.toggleOverlayClick(true);
    }
    onSubmit() {
        if (!this.isFormValid)
            this.highlightInvalidControls();
        this.$emit("onSubmit", this.isFormValid);
    }
    emitDelete() {
        this.$emit("onDelete", true);
    }
    onChangeEvent() {
        this.hasUserInput = true;
    }
    /**
     * Allow/Disallow to click on the sidenav overlay to close the panel
     */
    toggleOverlayClick(allow) {
        const overlays = document.querySelectorAll("div.sidenav-overlay");
        const options = { capture: true };
        overlays?.forEach((overlay) => {
            if (allow)
                overlay.removeEventListener("click", this.overlayClickHandler, options);
            else
                overlay.addEventListener("click", this.overlayClickHandler, options);
        });
    }
    highlightInvalidControls() {
        const form = this.$refs.appCreateForm;
        FormHelper.highlightInvalidControls(form);
    }
    onValueChange(showSidenav) {
        if (!showSidenav)
            this.toggleOverlayClick(true);
    }
    onIsPulsingChange(hasUserInput) {
        this.toggleOverlayClick(!hasUserInput);
    }
};
__decorate([
    VModel({ default: false })
], AppCreateSidenav.prototype, "showSidenav", void 0);
__decorate([
    Prop({ default: "Enregistrer" })
], AppCreateSidenav.prototype, "submitTitle", void 0);
__decorate([
    Prop({ default: "Enregistrer" })
], AppCreateSidenav.prototype, "submitLabel", void 0);
__decorate([
    Prop({ default: "Supprimer" })
], AppCreateSidenav.prototype, "deleteTitle", void 0);
__decorate([
    InjectReactive()
], AppCreateSidenav.prototype, "isSubmitting", void 0);
__decorate([
    InjectReactive()
], AppCreateSidenav.prototype, "title", void 0);
__decorate([
    InjectReactive()
], AppCreateSidenav.prototype, "showDelete", void 0);
__decorate([
    InjectReactive()
], AppCreateSidenav.prototype, "isFormValid", void 0);
__decorate([
    InjectReactive()
], AppCreateSidenav.prototype, "editedItem", void 0);
__decorate([
    Watch("showSidenav", { immediate: true })
], AppCreateSidenav.prototype, "onValueChange", null);
__decorate([
    Watch("hasUserInput")
], AppCreateSidenav.prototype, "onIsPulsingChange", null);
AppCreateSidenav = __decorate([
    Component({
        components: {
            AppContentSidenav,
        },
    })
], AppCreateSidenav);
export default AppCreateSidenav;
