import { __decorate } from "tslib";
import { Autocomplete } from "@materializecss/materialize";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let AppAutocomplete = class AppAutocomplete extends Vue {
    constructor() {
        super(...arguments);
        this.localValue = null;
        this.touched = false;
        this.autocomplete = null;
    }
    get selectData() {
        return {
            options: this.options,
            items: this.items,
        };
    }
    get isValid() {
        return ((!this.required ||
            (this.required &&
                this.localValue !== null &&
                this.localValue !== "" &&
                this.localValue !== undefined)) &&
            !this.error);
    }
    get dataTestName() {
        return "appTextField-" + this.name;
    }
    mounted() {
        this.initAutocomplete();
    }
    beforeDestroy() {
        this.autocomplete?.destroy();
    }
    addTitlesToList() {
        setTimeout(() => {
            const lis = this.$refs.autocompleteContainer?.querySelectorAll("li");
            lis.forEach((li) => {
                const label = li.innerText;
                const item = this.items?.find((i) => i.label === label);
                li.dataset.test = String(item?.value);
                if (item?.title)
                    li.title = item.title;
            });
        }, 0);
    }
    markAsTouched(e) {
        e.preventDefault();
        this.touched = true;
    }
    initAutocomplete() {
        const el = this.$refs.autocompleteContainer;
        if (el?.querySelector(".autocomplete"))
            this.autocomplete = Autocomplete.init(el.querySelector(".autocomplete"), {
                data: this.getAutocompleteData(),
                minLength: 0,
                ...this.options,
            });
    }
    destroyAutocomplete() {
        const el = this.$refs.autocompleteContainer;
        if (el?.querySelector(".autocomplete")) {
            const autocomplete = Autocomplete.getInstance(el.querySelector(".autocomplete"));
            autocomplete?.destroy();
        }
    }
    resetAutocomplete() {
        this.destroyAutocomplete();
        this.initAutocomplete();
    }
    getAutocompleteData() {
        return (this.items &&
            Object.fromEntries(this.items
                .map((f) => [f.label, null])
                .sort((a, b) => a[0].localeCompare(b[0]))));
    }
    onDataChanged(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
            this.$nextTick(() => this.resetAutocomplete());
        }
    }
    onValueChanged(val, oldVal) {
        if (val !== oldVal) {
            if (val === null || val === "")
                this.touched = false;
            this.localValue = val;
        }
    }
    onLocalValueChanged(val, oldVal) {
        if (val !== oldVal && this.items) {
            const value = this.items.find((i) => i.label === val)?.value || val;
            this.$emit("input", value);
        }
    }
};
__decorate([
    Prop({ default: "Sélectionner......" })
], AppAutocomplete.prototype, "label", void 0);
__decorate([
    Prop({ default: () => ({}) })
], AppAutocomplete.prototype, "options", void 0);
__decorate([
    Prop({ default: () => [] })
], AppAutocomplete.prototype, "items", void 0);
__decorate([
    Prop({ default: "" })
], AppAutocomplete.prototype, "value", void 0);
__decorate([
    Prop({ default: false })
], AppAutocomplete.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false })
], AppAutocomplete.prototype, "required", void 0);
__decorate([
    Prop({ default: true })
], AppAutocomplete.prototype, "showRequired", void 0);
__decorate([
    Prop({ default: false })
], AppAutocomplete.prototype, "validate", void 0);
__decorate([
    Prop({ required: true })
], AppAutocomplete.prototype, "name", void 0);
__decorate([
    Prop({ default: "" })
], AppAutocomplete.prototype, "hint", void 0);
__decorate([
    Prop({ default: "" })
], AppAutocomplete.prototype, "error", void 0);
__decorate([
    Watch("selectData")
], AppAutocomplete.prototype, "onDataChanged", null);
__decorate([
    Watch("value", { immediate: true })
], AppAutocomplete.prototype, "onValueChanged", null);
__decorate([
    Watch("localValue")
], AppAutocomplete.prototype, "onLocalValueChanged", null);
AppAutocomplete = __decorate([
    Component
], AppAutocomplete);
export default AppAutocomplete;
