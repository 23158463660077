import { __decorate } from "tslib";
import { NoCache } from "@/helpers/NoCache";
import { Sidenav } from "@materializecss/materialize";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
let AppContentSidenav = class AppContentSidenav extends Vue {
    constructor() {
        super(...arguments);
        this.instance = null;
    }
    get initData() {
        return [this.id, this.edge];
    }
    mounted() {
        this.init();
    }
    beforeDestroy() {
        this.instance?.destroy?.();
    }
    init() {
        this.instance?.destroy?.();
        this.instance = Sidenav.init(this.$refs.AppContentSidenav, {
            edge: this.edge,
            onOpenStart: () => (this.showSidenav = true),
            onCloseEnd: () => {
                this.$emit("closed");
                this.showSidenav = false;
            },
            ...this.options,
        });
        if (this.showSidenav)
            this.instance.open();
    }
    onInitDataChanged() {
        this.init();
    }
    onValueChanged(showSidenav) {
        if (showSidenav)
            this.instance?.open();
        else
            this.instance?.close();
    }
};
__decorate([
    Prop({ default: "app-content-sidenav" })
], AppContentSidenav.prototype, "id", void 0);
__decorate([
    Prop({ default: "right" })
], AppContentSidenav.prototype, "edge", void 0);
__decorate([
    Prop({ default: "100%" })
], AppContentSidenav.prototype, "width", void 0);
__decorate([
    Prop({ default: "auto" })
], AppContentSidenav.prototype, "minWidth", void 0);
__decorate([
    Prop({ default: "100%" })
], AppContentSidenav.prototype, "maxWidth", void 0);
__decorate([
    Prop({ default: "" })
], AppContentSidenav.prototype, "title", void 0);
__decorate([
    Prop({ default: true })
], AppContentSidenav.prototype, "showClose", void 0);
__decorate([
    Prop({ default: "large" })
], AppContentSidenav.prototype, "headerSize", void 0);
__decorate([
    Prop({ default: () => ({}) })
], AppContentSidenav.prototype, "options", void 0);
__decorate([
    VModel({ default: false })
], AppContentSidenav.prototype, "showSidenav", void 0);
__decorate([
    NoCache()
], AppContentSidenav.prototype, "initData", null);
__decorate([
    Watch("initData")
], AppContentSidenav.prototype, "onInitDataChanged", null);
__decorate([
    Watch("showSidenav")
], AppContentSidenav.prototype, "onValueChanged", null);
AppContentSidenav = __decorate([
    Component
], AppContentSidenav);
export default AppContentSidenav;
